import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import getAllGraphic from '../../../helper/user/getAllGraphic';

ChartJS.register(ArcElement, Tooltip, Legend);

const Dashboard = () => {
    const months = Array.from({ length: 12 }, (_, index) => index + 1);
    const monthNow = new Date().getMonth() + 1;
    const yearNow = new Date().getFullYear();
    const [month, setMonth] = useState(monthNow);
    const [year, setYear] = useState(yearNow);
    const [status, setStatus] = useState([]);
    const [monthsGraphic, setMonthGraphic] = useState([])

    const getInfo = async () => {
        const response = await getAllGraphic(year, month);
        setStatus([
            response.sending,
            response.pending,
            response.error
        ])

        const months = []
        for (const key in response.graphicSending) {
            months.push(response.graphicSending[key].total)
        }

        setMonthGraphic(months)

    }

    useEffect(() => {
        getInfo();
    }, [month, year])

    const dataPie = {
        labels: [`${status[0]} - Mensagens enviadas`, `${status[1]} - Mensagens pendentes`, `${status[2]} - mensagens com erro`],
        datasets: [
            {
                data: status,
                backgroundColor: [
                    'rgba(24, 236, 95, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 99, 132, 0.2)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const dataBar = {
        labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        datasets: [
            {
                label: 'Mensagem enviadas',
                backgroundColor: 'rgba(24, 236, 95, 0.2)',
                borderColor: 'rgba(24, 236, 95, 0.2)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(75, 192, 192, 0.8)',
                hoverBorderColor: 'rgba(75, 192, 192, 1)',
                data: monthsGraphic,
            },
        ],
    };

    return (
        <div className="container-fluid">
            <div className="col-lg-12 d-flex align-items-stretch">
                <div className="card w-100">
                    <div className="card-body p-4">

                        <div className='container-title-card'>
                            <h5 className="card-title fw-semibold mb-4">Dashboard Whatsapp</h5>
                            <div className='help-user'>
                                <a target='_blank' href="https://www.youtube.com/@Platformbot-um5ok/videos" ><img className='help-img-yt' src="/assets/images/1384060.png" />Aprenda usar</a>
                            </div>
                        </div>

                        <div className='container-filter-graphic'>
                            <div>
                                <label>Ano: </label><br></br>
                                <select value={year} onChange={(e) => setYear(Number(e.target.value))}>
                                    <option>2023</option>
                                    <option>2024</option>
                                </select>
                            </div>
                            <div>
                                <label>Mês: </label><br></br>
                                <select value={month} onChange={(e) => setMonth(Number(e.target.value))}>
                                    {months.map((month) => (
                                        <option key={month} value={month}>
                                            {month}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="chart-js mt-4">
                            <div >
                                <Pie data={dataPie} />
                            </div>
                            <div className="chart-bar" >
                                <Bar data={dataBar} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
