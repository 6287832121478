import React, { useEffect, useState } from 'react';
import getAllChats from '../../../helper/user/whatsapp/getAllChats';
import ExcelJS from 'exceljs';
import checkLoginWp from '../../../helper/user/whatsapp/checkLoginWp';
import Cookies from 'js-cookie';

const Chats = () => {
    const [allContacts, setAllContacts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [conectadoWp, setConectadoWp] = useState(true)
    const [pro, setPro] = useState(false);

    const checkPro = async () => {
        let user = Cookies.get('user')
        user = JSON.parse(user)
        setPro(user.plan)
    }

    const fetchContacts = async () => {
        setLoading(true);
        let contacts = await getAllChats();
        if (!contacts.data) {
            return [];
        }
        setAllContacts(contacts.data);
        setLoading(false);
    };

    useEffect(() => {
        fetchContacts();
        checkLogin();
        checkPro();
    }, []);

    const exportToExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Contatos Não Salvos');

        // Adicionar cabeçalhos
        worksheet.columns = [
            { header: 'Número', key: 'number', width: 15 },
            { header: 'Nome', key: 'name', width: 20 }
        ];

        // Adicionar dados
        allContacts.forEach((contact) => {
            worksheet.addRow({
                number: contact.phoneNumber,
                name: contact.name,
                verifiedName: contact.verifiedName,
            });
        });

        // Criar o arquivo Excel
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const fileName = 'contatos_nao_salvos.xlsx';
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = href;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const exportToTxt = () => {
        const txtContent = allContacts
            .map((contact) => `${contact.phoneNumber}`)
            .join('\n');

        const blob = new Blob([txtContent], { type: 'text/plain' });
        const fileName = 'contatos_nao_salvos.txt';
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = href;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const checkLogin = async () => {
        const logged = await checkLoginWp()
        setConectadoWp(logged)
    }

    return (
        <div className="container-fluid">
            {!conectadoWp &&
                <div class="alert alert-warning" role="alert">
                    Para visualizar essa pagina você precisa estar logado no whatsapp, <a href="/home">clique aqui</a> para realizar o login
                </div>
            }
            {(!pro && conectadoWp) && (
                <div className="alert alert-danger" role="alert">
                    Atenção seus contatos estão mascarado com "***" para remover assine um plano pro, <a href="/profile">clique aqui</a>
                </div>
            )
            }
            <div className="col-lg-12 d-flex align-items-stretch">
                <div className="card w-100">
                    {conectadoWp &&
                        <div className="card-body p-4">

                            <div className='container-title-card'>
                                <h5 className="card-title fw-semibold mb-4">Contatos não salvos</h5>
                                <div className='help-user'>
                                    <a target='_blank' href="https://www.youtube.com/@Platformbot-um5ok/videos" ><img className='help-img-yt' src="/assets/images/1384060.png" />Aprenda usar</a>
                                </div>
                            </div>

                            {!loading && <div>
                                <button className="btn btn-outline-primary m-1" onClick={exportToExcel}>Exportar para Excel</button>
                                <button className="btn btn-outline-primary m-1" onClick={exportToTxt}>Exportar para TXT</button>
                            </div>}
                            <div className="table-responsive">
                                <p>{loading && 'Carregando... aguarde... isso pode demorar depende da quantidade de dados em seu whatsapp'}</p>
                                {!loading &&
                                    <table id="table" className="table text-nowrap mb-0 align-middle">
                                        <thead className="text-dark fs-4">
                                            <tr>
                                                <th className="border-bottom-0">
                                                    <h6 className="fw-semibold mb-0">Numero</h6>
                                                </th>
                                                <th className="border-bottom-0">
                                                    <h6 className="fw-semibold mb-0">Nome</h6>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allContacts.map((contact, index) =>
                                                    <tr key={index}>
                                                        <td className="border-bottom-0">
                                                            <p className="mb-0 fw-normal">{contact.phoneNumber}</p>
                                                        </td>
                                                        <td className="border-bottom-0">
                                                            <h6 className="fw-semibold mb-1">{contact.name}</h6>
                                                        </td>
                                                    </tr>
                                                )}
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>

        </div>
    );
}

export default Chats;
