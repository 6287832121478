import React, { useEffect, useState } from 'react';
import QrCodeWp from './qrCodeWp';
import TelegramCode from './telegramCode';
import Cookies from 'js-cookie';

const Home = () => {
    const [scope, setScope] = useState('')
    useEffect(() => {
        const cookieScope = Cookies.get('scope') 
        setScope(cookieScope)
    })

    return (
        <div className="container-fluid flex-container">
            {scope == "whatsapp" &&
            <QrCodeWp/>
            }
            {scope == "telegram" &&
            <TelegramCode/>
            }
            <div className='help-user'>
                <a target='_blank' href="https://www.youtube.com/@Platformbot-um5ok/videos" ><img className='help-img-yt' src="/assets/images/1384060.png" />Aprenda usar</a>
            </div>
        </div>
    )
}

export default Home