
import Cookies from 'js-cookie';

const readQrCode = async () => {

    const authToken = Cookies.get('authToken');
    if (!authToken) {
        return false;
    }

    let response = await fetch(`${process.env.REACT_APP_API_WEB_BOOT}bot/readqrcode`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }
    });

    const data = await response.json()

    return data
}

export default readQrCode