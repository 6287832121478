import React, { useEffect, useState } from 'react';
import createdScheduling from '../../../helper/user/createdScheduling';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import EmojiPicker from 'emoji-picker-react';

const SendCreated = () => {
    const [openEmogis, setOpenEmogis] = useState(false);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [fileContent, setFileContent] = useState('');
    const [startDate, setStartDate] = useState('');
    const [messageInterval, setMessageInterval] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const [nameError, setNameError] = useState(false);
    const [fileError, setFileError] = useState(false);
    const [startDateError, setStartDateError] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [messageIntervalError, setMessageIntervalError] = useState(false);
    const [maximumSender, setMaximumSender] = useState(1);
    const [image, setImage] = useState(false);
    const [audio, setAudio] = useState(false);
    const [plan, setPlan] = useState(0);
    const [generalMessage, setGeneralMessage] = useState(false);

    const checkPlan = () => {
        let user = Cookies.get('user');
        user = JSON.parse(user);
        setMaximumSender(parseInt(user.maximum_senders));
        setPlan(parseInt(user.plan));
    };

    useEffect(() => {
        checkPlan();
    }, []);

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        const reader = new FileReader();

        reader.onload = () => {
            const base64Image = reader.result;
            setImage(base64Image.split(';base64,')[1])
        };

        reader.readAsDataURL(file);
    };

    const handleAudioChange = (e) => {
        const file = e.target.files[0];

        const reader = new FileReader();

        reader.onload = () => {
            const base64Image = reader.result;
            setAudio(base64Image.split(';base64,')[1])
        };

        reader.readAsDataURL(file);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const content = e.target.result;
                const cleanedContent = content.replace(/\r/g, '');
                const lines = cleanedContent.split('\n');
                if (lines.length > 200) {
                    setFileError(true);
                } else {
                    setFileContent(lines);
                    setFileError(false);
                }
            };
            reader.readAsText(file);
        }
    };

    function isValidTimeFormat(value) {
        const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
        return timeRegex.test(value);
    }

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        setGeneralMessage(false);
        if (!name || !fileContent || !startDate || !messageInterval) {
            setNameError(!name);
            setFileError(!fileContent);
            setStartDateError(!startDate);
            setMessageIntervalError(!messageInterval);
            return;
        }

        if (!message && !image && !audio) {
            setGeneralMessage(true);
            return;
        }

        const now = new Date();
        const selectedStartDate = new Date(startDate);
        if (selectedStartDate < now) {
            setStartDateError(true);
            return;
        }

        if (!isValidTimeFormat(messageInterval) || messageInterval < '00:00:30') {
            setMessageIntervalError(true);
            return;
        }

        setLoading(true);
        const body = {
            name,
            date: startDate.replace("T", " "),
            time: messageInterval,
            message,
            contacts: fileContent
        };

        if (image != false) {
            body.image = image;
        }

        if (audio != false) {
            body.voicePath = audio;
        }

        const response = await createdScheduling(body);
        if (!response) {
            alert('Erro ao criar agendamento, tente novamente');
            setLoading(false);
            return;
        }
        navigate('/send');
    };

    const setValueMessage = (event) => {
        setMessage(event.target.value);
    };
    const emojiSelect = (emojiData, event) => {
        setOpenEmogis(false);
        setMessage(prevMessage => prevMessage + emojiData.emoji);
    };

    return (
        <div className="container-fluid">
            {plan == 0 && maximumSender <= 0 && <div class="alert alert-danger" role="alert">
                Você já ultrapassou o limite de mensagem no plano gratuito, suas mensagem não serão mais enviadas, atualize para continuar usando
            </div>}
            <div className="col-lg-12 d-flex align-items-stretch">
                <div className="card w-100">
                    <div className="card-body p-4">
                        <div className='container-title-card'>
                            <h5 className="card-title fw-semibold mb-4">Criar agendamento de envios</h5>
                            <div className='help-user'>
                                <a target='_blank' href="https://www.youtube.com/@Platformbot-um5ok/videos" ><img className='help-img-yt' src="/assets/images/1384060.png" />Aprenda usar</a>
                            </div>
                        </div>
                        <div>
                            <a href="/send" className="btn btn-outline-primary m-1">
                                Voltar
                            </a>
                        </div>

                        <form>
                            <div className={`mb-3 mt-2 ${nameError ? 'has-error' : ''}`}>
                                <label className="form-label">Nome:</label>
                                <input
                                    onChange={(e) => {
                                        setName(e.target.value);
                                        setNameError(false); // Limpar o erro quando o usuário começar a digitar
                                    }}
                                    className={`form-control ${nameError ? 'is-invalid' : ''}`}
                                />
                                {nameError && <div className="invalid-feedback">Campo obrigatório</div>}
                                <div id="emailHelp" className="form-text">
                                    Escolha o nome do seu agendamento.
                                </div>
                            </div>
                            <div className={`mb-3 mt-2 ${fileError ? 'has-error' : ''}`}>
                                <label className="form-label">Carregar arquivo txt</label>
                                <input
                                    type="file"
                                    className={`form-control ${fileError ? 'is-invalid' : ''}`}
                                    accept=".txt"
                                    onChange={handleFileChange}
                                />
                                {fileError && <div className="invalid-feedback">Limite de 200 linhas, campo obrigatório</div>}
                                <div id="emailHelp" className="form-text">
                                    Carregue arquivo txt com listagem de contatos para ser enviado maximo de (200 linhas).
                                </div>
                            </div>
                            <div className={`mb-3 mt-2 ${startDateError ? 'has-error' : ''}`}>
                                <label className="form-label">Data de inicio:</label>
                                <input
                                    onChange={(e) => {
                                        setStartDate(e.target.value);
                                        setStartDateError(false);
                                    }}
                                    type="datetime-local"
                                    className={`form-control ${startDateError ? 'is-invalid' : ''}`}
                                />
                                {startDateError && <div className="invalid-feedback">Campo obrigatório e deve ser maior ou igual a data atual, o horário deve ser maior que o atual</div>}
                                <div className="form-text">Data que começara os envios.</div>
                            </div>
                            <div className={`mb-3 mt-2 ${messageIntervalError ? 'has-error' : ''}`}>
                                <label className="form-label">Tempo entre mensagem:</label>
                                <input
                                    placeholder='00:00:00'
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setMessageInterval(value);
                                        setMessageIntervalError(!isValidTimeFormat(value));
                                    }}
                                    type="text"
                                    className={`form-control ${messageIntervalError ? 'is-invalid' : ''}`}
                                />
                                {messageIntervalError && <div className="invalid-feedback">Formato inválido. Use HH:mm:ss, Minimo de 30 segundos</div>}
                                <div className="form-text">Configure o tempo entre as mensagens (no formato HH:mm:ss). Minimo de 30 segundos</div>
                            </div>
                            <div className={`mb-3 mt-2 ${messageError ? 'has-error' : ''}`}>
                                <label className="form-label">Mensagem:</label>
                                <textarea
                                    value={message}
                                    onChange={(e) => {
                                        setValueMessage(e);
                                        setMessageError(false);
                                    }}
                                    className={`form-control heigth-textarea ${messageError ? 'is-invalid' : ''}`}
                                ></textarea>
                                {openEmogis && <p onClick={() => setOpenEmogis(!openEmogis)}>🥲Fechar</p>}
                                {!openEmogis && <p onClick={() => setOpenEmogis(!openEmogis)}>😄Emogis</p>}
                                {openEmogis &&
                                    <EmojiPicker
                                        onEmojiClick={emojiSelect}
                                    />}
                                <div className="form-text">Configure a mensagem para ser enviada, campo opcional caso imagem ou audio seja configurado</div>
                            </div>
                            <div className={`mb-3 mt-2 ${fileError ? 'has-error' : ''}`}>
                                <label className="form-label">Imagem</label>
                                <input
                                    type="file"
                                    className='form-control'
                                    accept=".png, .jpg, .jpeg"
                                    onChange={handleImageChange}
                                />
                                <div id="emailHelp" className="form-text">
                                    Carregue uma imagem para ser enviada, formatos .png, .jpg, .jpeg, campo opcional caso audio ou mensagem seja configurado
                                </div>
                            </div>
                            <div className={`mb-3 mt-2 ${fileError ? 'has-error' : ''}`}>
                                <label className="form-label">Audio</label>
                                <input
                                    type="file"
                                    className='form-control'
                                    accept=".mp3"
                                    onChange={handleAudioChange}
                                />
                                <div id="emailHelp" className="form-text">
                                    Carregue um audio para ser enviado, formato .mp3, campo opcional caso mensagem ou imagem seja configurado
                                </div>
                            </div>
                            {
                                generalMessage && (
                                    <div className="alert alert-danger" role="alert">
                                        Um dos campos Mensagem, Imagem ou Audio, deve ser preenchidos
                                    </div>
                                )
                            }
                            {(plan == 0 && maximumSender > 0 || plan == 1) && <button disabled={loading} onClick={handleFormSubmit} className="btn btn-outline-primary">
                                {loading ? 'Enviando...' : 'Cadastrar'}
                            </button>}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SendCreated;
