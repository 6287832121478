import React, { useEffect, useState } from 'react';
import Plan from '../partial/plan';
import newSession from '../../helper/user/telegram/newSession'
import validCode from '../../helper/user/telegram/validCode'
import isLogged from '../../helper/user/telegram/isLogged'

const TelegramCode = () => {
    const [loading, setLoading] = useState(false);
    const [loadingCheckLogged, setLoadingCheckLogged] = useState(true);
    const [message, setMessage] = useState("");
    const [messageError, setMessageError] = useState("");
    const [isConected, setIsConected] = useState(false);
    const [telegramNumber, setTelegramNumber] = useState('')
    const [codeValid, setCodeValid] = useState('')
    const [isBlurContact, setIsBlurContact] = useState('')
    const [isBlurCode, setIsBlurCode] = useState('blur-1')

    useEffect(() => {
        checkLogin()
    },[])

    const checkLogin = async () => {
        if (!telegramNumber) return

        const data = {
            phoneNumber: telegramNumber
        }
        const logged = await isLogged(data)

        if (logged.statusCode !== 200) {
            return
        }

        setIsConected(true)
    }

    const handlePhone = (event) => {
        let input = event.target;
        let value = input.value;
    
        if (value.length > 19) {
          value = value.substring(0, 19);
        }

        input.value = phoneMask(value);
        setTelegramNumber(input.value)
    };
    
    const sendCodeTelegram = async () => {
        setLoading(true)
        if (!telegramNumber) {
            setMessageError('Informe o número e tente novamente')
            setLoading(false)
            return
        }

        const unformattedNumber = telegramNumber.replace(/[^\d+]/g, '');

        setTelegramNumber(unformattedNumber)

        const data = {
            phoneNumber: unformattedNumber
        }
        const session = await newSession(data)

        if (session.statusCode !== 200) {
            setMessageError(`${session.message}, verifique o número e tente novamente `)
            setLoading(false)
            return
        }

        setIsBlurContact('blur-1')
        setIsBlurCode('')

        setLoading(false)
    }

    const validCodeTelegram = async () => {
        setLoading(true)
        if (!codeValid) {
            setMessageError('Informe o codigo enviado e tente novamente')
            setLoading(false)
            return
        }
        const data = {
            phoneNumber: telegramNumber,
            verificationCode: codeValid
        }

        const valid = await validCode(data)

        if (valid.statusCode !== 200) {
            setMessageError(`${valid.message}, verifique e tente novamente `)
            setLoading(false)
            return
        }

        setIsConected(true)
    }

    const phoneMask = (value) => {
        if (!value) return ""
        value = value.replace(/\D/g, ''); // Remove todos os caracteres que não são dígitos
        value = value.replace(/^(\d{2})(\d)/g, "+$1 $2"); // Adiciona o código do país (exemplo: +55)
        value = value.replace(/(\d{2})(\d)/, "($1) $2"); // Adiciona o código de área entre parênteses
        value = value.replace(/(\d)(\d{4})$/, "$1-$2"); // Adiciona o traço entre os quatro últimos dígitos
        return value;
      }

    return (
        <div>
            {!isConected &&
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="card ">
                                <div class="card-body">
                                    <div class={`${isBlurContact}`}>
                                        <h5 class="card-title">Conecte com sua conta telegram</h5>
                                        <p class="card-text">Informe seu número e clique em conectar para receber o código</p>
                                        <input onInput={e => handlePhone(e)}  class="form-control  mb-4" placeholder='+55 (00) 00000-0000'  />
                                    </div>
                                    <div class={`${isBlurCode} mt-4`}>
                                        <label class="mb-2">Informe o código enviado no seu telegram</label>
                                        <input onInput={e => setCodeValid(e.target.value)} class="input-code-telegram mb-4" placeholder='0 0 0 0 0'  />
                                    </div>
                                    {isBlurCode != "" &&  <button onClick={e => sendCodeTelegram()} disabled={loading}  class="btn btn-primary">{loading ? "Carregando..." : "Conectar"}</button>}
                                    {isBlurCode == "" &&  <button onClick={e => validCodeTelegram()} disabled={loading}  class="btn btn-primary">{loading ? "Carregando..." : "Validar"}</button>}
                                    <br></br>
                                    <b>{message}</b>
                                    <p>{messageError}</p>
                                </div>
                            </div>                      
                        </div>
                        <div class="col-md-4">
                            <Plan/>
                        </div>
                    </div>
                </div>
             }
            {isConected &&
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4">
                           <div class="card card-img">
                                <img src="../assets/images/sucess.png" class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Você esta conectado com sucesso no telegram</h5>
                                    <button  class="btn btn-danger mt-3">Desconectar</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <Plan/>
                        </div>
                    </div>
                </div>
             }
        </div>
    )
}

export default TelegramCode